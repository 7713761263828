<template>
  <div v-if="getUser.role.slug === 'super-administrateur'">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
            <b-row>
              <b-col cols="12" sm="6" md="4" lg="4">
               <label for="payment_method">Moyen de payement</label>
                      <v-select
                        v-model="payloadFilter.payment_method"
                        :options="paymentMethodOptions"
                        class="invoice-filter-select"
                        :reduce="(item) => item.value"
                      >
                        <template #selected-option="{ label }">
                          <span class="text-truncate overflow-hidden">
                            {{ label }}
                          </span>
                        </template>
                      </v-select>
                </b-col>
                <b-col cols="12" sm="6" md="4" lg="4">
                <label for="status">Statut</label>
               <v-select
                        v-model="payloadFilter.status"
                        :options="statusOptions"
                        class="invoice-filter-select"
                        :reduce="(item) => item.value"
                      >
                        <template #selected-option="{ label }">
                          <span class="text-truncate overflow-hidden">
                            {{ label }}
                          </span>
                        </template>
                      </v-select>
                </b-col>

               <b-col cols="12" sm="6" md="4" lg="4">
                <label for="service">Date de Transaction</label>
                <b-form-group>
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Sélectionner une intervalle de date"
                  :config="config"
                />
              </b-form-group>
              </b-col>
              
            </b-row>
          </b-col>
        </b-row>
        <b-row>
                    <b-col cols="12" sm="6" md="5" lg="5">
                      <label for="status">Type</label>
                      <v-select
                        v-model="payloadFilter.type"
                        :options="typeOptions"
                        class="invoice-filter-select"
                        :reduce="(item) => item.value"
                      >
                        <template #selected-option="{ label }">
                          <span class="text-truncate overflow-hidden">
                            {{ label }}
                          </span>
                        </template>
                      </v-select>
                    </b-col>

                    <b-col cols="12" sm="6" md="5" lg="5">
                      <label for="advance">Téléphone</label>
                      <b-form-input
                      id="firstname"
                      v-model="phone"
                      type="number"
                      placeholder="Entrez le numéro de téléphone sans 229"
                    />
                    </b-col>
                    
                    <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                <b-button
                  variant="primary"
                  @click="applyFilterTransactionsAction()"
                >
                  <div v-if="isFilterTransactions">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                </b-button>
              </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card title="Liste  des transactions effectuées">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button
            variant="primary"
            @click="reLoadDataTransactionsAction()"
          >
            <div v-if="isReLoadDataTransactions">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span v-else class="text-nowrap font-medium-1">Actualiser</span>
          </b-button>
        </b-col>
        
        
      </b-row>
     
        
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :is-loading="isTransactionsLoading || isFilterTransactionsLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isTransactionsLoading || isFilterTransactionsLoading? "" : "Liste vide" }} </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </template>

        <template slot="table-row" slot-scope="props">
          <div
            v-if="props.column.field === 'author'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.author
            }}</span>
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.phoneNumber
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'amount'"
            class="text-nowrap text-center align-middle"
          >
            <span
              class="d-block text-nowrap text-info font-small-5"
            >
                {{ props.row.amount }} FCFA
            </span>
          </div>
          <div
            v-else-if="props.column.field === 'transaction_date'"
            class="text-nowrap text-center align-middle"
          >
            <span
              class="d-block text-nowrap text-info font-small-5"
            >
                {{ moment(props.row.created_at).locale("fr").format("llll") }}
                 
            </span>
          </div>
          <div
            v-else-if="props.column.field === 'status'"
            class="text-nowrap text-center align-middle"
          >
            <span
              
              v-if="props.row.status =='SUCCESSFUL'"
            >
            <b-badge pill variant="light-success" class="text-capitalize font-medium-2">SUCCES</b-badge>    
            </span>
            <span
              v-else
            >
             <b-badge pill variant="light-danger" class="text-capitalize font-medium-2">ECHOUE</b-badge>    
            </span>
          </div>
           <div
            v-else-if="props.column.field === 'payment_method'"
            class="text-nowrap text-center align-middle"
          >
            <span
              class="d-block text-nowrap text-info font-small-5"
            >
                {{ props.row.payment_method }}
            </span>
          </div>
           <div
            v-else-if="props.column.field === 'type'"
            class="text-nowrap text-center align-middle"
          >
            <span
              class="d-block text-nowrap text-info font-small-5"
            >
                {{ props.row.type }}
            </span>
          </div>

        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreTransactionsAction(metaData.prev_page_url)"
        >
          <span class="text-white text-nowrap font-medium-2"
            >Page précédente</span
          >
        </b-button>

        <div>
          <span class="text-white font-medium-2"
            >{{ metaData.current_page }} sur
            {{ metaData.last_page }} pages</span
          >
        </div>
        <div>
          <span class="text-white font-medium-2"
            >Total : {{ total }}</span
          >
        </div>
        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreTransactionsAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white"
            >Page suivante</span
          >
        </b-button>
      </div>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCardHeader,
  BCardBody,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    vSelect,
    VueGoodTable,
    flatPickr,
  },
  data() {
    return {
      rangeDate: null,
      config: { mode: 'range' },
        paymentMethodOptions: [
        {
          label: "MTN",
          value: "MTN",
        },
        {
          label: "MOOV",
          value: "MOOV",
        },
      ],
       statusOptions: [
        {
          label: "SUCCESS",
          value: "SUCCESSFUL",
        },
        {
          label: "ECHOUE",
          value: "FAILED",
        },
      ],
     phone: "",
      rangeDateFilter: "",
      isReLoadDataTransactions: false,
      rows: [],
      employeeOptions: [],
      soldeFilter: null,
      soldeOptions: [
        {
          label: "Positif",
          value: "ps",
        },
        {
          label: "Négatif",
          value: "nf",
        },
      ],
      isFilterTransactionsLoading: false,
      payloadFilter: {
        payment_method: "",
        transaction_date: "",
        status: "",
        start_date: "",
        end_date: "",
        phoneNumber: "",
        type: "",

      },
      typeOptions:[
        {
          label: "Commande pontuelle",
          value: "CP",
        },
        {
          label: "Commande récurrente",
          value: "CR",
        },
        {
          label: "Dépôt dans le portefeuille",
          value: "DW",
        },
        {
          label: "Paiement salaire",
          value: "PS",
        },
        {
          label: "Retrait",
          value: "WM",
        },
        {
          label: "Avance salaire",
          value: "AS",
        },
      ],
      columns: [
        {
          label: "Auteur",
          field: "author",
        },
        {
          label: 'Montant Envoyé',
          field: 'amount'
        },
        {
          label: 'Moyen de paiement',
          field: 'payment_method'
        },
        {
          label: 'Statut',
          field: 'status'
        },
        {
          label: 'Date de transaction',
          field: 'transaction_date'
        },
        {
          label: 'Motif de transaction',
          field: 'type'
        }
      ],

      isFilterTransactions: false,
      avatarText,

      fields: {
        "Nom complet": "Nom complet",
        Age: "Age",
        Téléphone: "Téléphone",
        "Adresse de résidence": "Adresse de résidence",
        "Adresse mail": "Adresse mail",
        "Services fournies": "Services fournies",
        "Statut de l'employé": "Statut de l'employé",
      },
    };
  },
  computed: {
   
    ...mapGetters([
      "isTransactionsLoading",
      
    ]),
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("orders", ["getTransactions"]),

    metaData() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        current_page_url:'',
        last_page: "",
        total: "",
      };
      if (this.getTransactions != null) {
        meta.prev_page_url = this.getTransactions.prev_page_url;
        meta.next_page_url = this.getTransactions.next_page_url;
        meta.current_page = this.getTransactions.current_page;
        meta.last_page = this.getTransactions.last_page;
        this.total = this.getTransactions.total;
      }
      return meta;
    },


    phone_number() {
      return this.phone.replace(/\s/g, "");
    },
  
  },

  watch: {
    getTransactions(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.rows = this.getTransactions.data;
      }
    },
    isTransactionsLoading(val) {
      
      if (val === false) {
        this.rows = this.getTransactions.data;
        console.log(this.rows)
      }
    },
     /* rangeDate(val) {
      if (val != null && val.split('to').length === 2) {
        console.log(val.split('to').length)
        this. applyFilterTransactionsAction(val)
      }
    }, */
  
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        keys.forEach((key) => {
          if (newValue[key] == null) {
            this.reLoadDataTransactionsAction();
            newValue[key] = "";
          }
        });
      },
      deep: true,
    },
  },

  mounted() {
    
  },

  created() {
    // console.log(this.rows)
    
    this.getTransactions.data != null
      ? (this.rows = this.getTransactions.data)
      : null;
    console.log(this.rows)
  },

  methods: {
  
    ...mapActions("orders", ["getTransactionsAction","filterTransactionsAction", "loadMoreTransactionsAction" ]),
    

    applyFilterTransactionsAction(val) {
      this.metaData.current_page= ""
      this.metaData.last_page = ""
      this.metaData.next_page_url == null
      console.log(this.rangeDate , "Rangedate")
      const allIsNUl = Object.values(this.payloadFilter).every(
        (el) => el === ""
      );
      if ((allIsNUl && this.rangeDate == null) && this.phone_number == "" ) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {
        this.rows = [];
        this.isFilterTransactions = true; 
        this.isFilterTransactionsLoading = true;

        
        if(this.rangeDate !== null){
          this.payloadFilter.start_date = this.rangeDate.split('to')[0],
          this.payloadFilter.end_date = this.rangeDate.split('to')[1]
        }
        else{
          this.payloadFilter.start_date = ""
          this.payloadFilter.end_date = ""
        }
        if(this.phone_number !== ""){
         this.payloadFilter.phoneNumber = `229${this.phone_number}`
        }
        this.filterTransactionsAction(this.payloadFilter)
          .then((response) => {
            this.isFilterTransactions = false;
            this.isFilterTransactionsLoading = false;
            this.rows = response.data
            console.log(this.metaData.current_page, "Pagination")
            console.log(this.metaData.last_page, "Pagination")
            this.total = this.rows.length
          })
          .catch((error) => {
            console.log(error);
            this.isFilterTransactions = false;
            this.isFilterTransactionsLoading = false;
             this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Erreur",
              icon: "TriangleAlertIcon",
              text: error.response.data.message,
              variant: "danger",
            },
          },
          {
            position: "top-center",
          }
        );
          });
      }
    },
    reLoadDataTransactionsAction() {
      this.phone_number = "",
      this.payloadFilter ={
        payment_method: "",
        type: "",
        phoneNumber: "",
        status: "",
        start_date: "",
        end_date: "",
      }
      
      this.isReLoadDataTransactions = true;
      this.$store.commit("SET_IS_TRANSACTIONS_LOADING", true);
      this.rows = [];
      this.getTransactionsAction()
        .then((response) => {
          this.isReLoadDataTransactions = false;
          this.$store.commit("SET_IS_TRANSACTIONS_LOADING", false);
          this.rangeDate = null
        })
        .catch((error) => {
          this.isReLoadDataTransactions = false;
          this.$store.commit("SET_IS_TRANSACTIONS_LOADING", false);
        });
    },

    showEmployeeWalletDetails(wallet) {
      this.$router.push({
        name: "admin-employees-wallet",
        params: { id: wallet.id },
      });
    },
    applyLoadMoreTransactionsAction(url) {
      this.$store.commit("SET_IS_TRANSACTIONS_LOADING", true);
      this.rows = [];
      this.loadMoreTransactionsAction(url)
        .then(() => {
          this.$store.commit("SET_IS_TRANSACTIONS_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_TRANSACTIONS_LOADING", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
